import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

const OfficeInfo = ({ city, address }) => {
  return (
    <Grid
      templateColumns={{ laptop: '12px auto', sm: '6px auto' }}
      templateRows={{ desktop: '34px auto', laptop: '28px auto', sm: '22px auto' }}
      gap={{ laptop: '12px', sm: '8px' }}
    >
      <Box
        borderRadius="full"
        placeSelf="center"
        boxSize={{ laptop: '12px', sm: '6px' }}
        bgColor="bg.accent"
      ></Box>
      <Flex align="center" textStyle="h1" lineHeight={{ laptop: '140%', tablet: '120%' }}>
        {city}
      </Flex>
      <Text
        textStyle="h3"
        gridColumn={2}
        gridRow={2}
        color="#A7A9A5"
        dangerouslySetInnerHTML={{ __html: address }}
        fontSize={{ laptop: '16px', tablet: '12px' }}
      ></Text>
    </Grid>
  );
};

export default OfficeInfo;
