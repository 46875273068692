export const radioStatusesSelector = ({
  isLatestLoaded,
  isTop8Loaded,
  isRubricsLoaded,
  isRadioRubricLoaded,
}) => ({
  isLatestLoaded,
  isTop8Loaded,
  isRubricsLoaded,
  isRadioRubricLoaded,
});
