import { keyframes } from '@emotion/react';

export const spinAnimation = keyframes`
from{
	transform: rotate(0deg);
}
to{
	transform: rotate(360deg);
}
`;
