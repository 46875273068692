export const radioSelector = ({
  rubrics,
  latest,
  top8,

  fetchRubrics,
  fetchRubric,
}) => ({
  rubrics,
  latest,
  top8,

  fetchRubrics,
  fetchRubric,
});
