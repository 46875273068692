const commonAssets = {
  favicon:
    'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/favicon/AstonFavicon.svg',
  logo: {
    Aston: {
      light:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/logo/AstonLogo_light.svg',
      dark: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/logo/AstonLogo_dark.svg',
    },
  },
  icons: {
    Aston:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/AstonIcon.svg',
    Burger:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Burger.svg',
    Check:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Check.svg',
    Cross:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Cross.svg',
    Minus:
      'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Minus.svg',
    Plus: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Plus.svg',
    Phone: {
      default:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Phone.svg',
      black:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Phone_black.svg',
    },
    Mail: {
      default:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Mail.svg',
      green:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Mail_green.svg',
      black:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/Mail_black.svg',
    },
    Angle: {
      down: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/DownAngle.svg',
      left: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/LeftAngle.svg',
      up: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/UpAngle.svg',
      right:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/RightAngle.svg',
    },
    Arrow: {
      down: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/DownArrow.svg',
      up: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/UpArrow.svg',
      left: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/LeftArrow.svg',
      right:
        'https://storage.yandexcloud.net/dev.astonsite.s3backet/aston-redisign/common/icons/RightArrow.svg',
    },
  },
};
export default commonAssets;
