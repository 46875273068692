import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { getApiUrl } from 'utils/environment';
import { DOMAIN_URL } from 'define';
import commonAssets from 'assets/common';

export default function Head(props) {
  const config = useMemo(() => ({ ogType: 'website', ...props }), []);
  const [description, setDescription] = useState(config.description);
  const pathname = useLocation().pathname;
  const lang = process.env.GATSBY_LANG || 'ru';
  const location = `${DOMAIN_URL}${pathname}`;

  useEffect(() => {
    const isMobile = window?.innerWidth < 960;

    if (isMobile) setDescription(config.descriptionMobile);
  }, []);

  return (
    <Helmet>
      <html lang={lang} />
      <title>{config.title || 'astondevs.ru'}</title>
      <meta name="description" content={description} />
      <meta name="google-site-verification" content="HLc2atidn-ywcwaG8v1JSi2awbmk8jF1Cl1p-3XC2LM" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"></meta>
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Medium.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-MidRegular.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Regular.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link rel="icon" href={commonAssets.favicon} sizes="any" />
      <link rel="canonical" href={location} />
      <link rel="preconnect" href={getApiUrl()} />
      <link rel="preconnect" href="https://www.google.com" />
      {config.schema?.map((item, i) => (
        <script key={i} type="application/ld+json">
          {JSON.stringify(item)}
        </script>
      ))}
    </Helmet>
  );
}
