export const ym = (event, params) =>
  'ym' in window &&
  (params
    ? window.ym(90274501, 'reachGoal', event, params)
    : window.ym(90274501, 'reachGoal', event));

export const sendRadioYM = (category_name, episode_name, time_listened) => {
  ym('radio_activity', {
    player_usage: { category_name, episode_name, time_listened },
  });
};
