import { Fonts } from 'shared/Fonts';
import React from 'react';

function RootWrapper({ element }) {
  return (
    <>
      <Fonts />
      {element}
    </>
  );
}

export default RootWrapper;
