import Api from 'shared/backApi';
import { toUpperCaseFirst } from 'shared/lib/toUpperCaseFirst';

import { podcastsDataByRubrics } from 'constants/radioPage/podcastsDataByRubrics';

export const createRadioSlice = (set, get) => ({
  // radioSelector
  rubrics: {},
  latest: [],
  top8: [],

  fetchRubrics: async () => {
    try {
      const rubricsData = await Api.getPodcastsRubrics();

      const _rubrics = rubricsData.reduce((acc, { id, rubric_name }) => {
        const rubricData = podcastsDataByRubrics[rubric_name] ?? podcastsDataByRubrics.default;

        acc[rubric_name] = {
          id,
          name: rubric_name,
          episodes: [],
          isLoaded: false,
          ...rubricData,
        };

        return acc;
      }, {});

      set({ rubrics: _rubrics, isRubricsLoaded: true });
    } catch (e) {
      return e;
    }
  },
  fetchRubric: async rubricName => {
    try {
      let rawPodcastsData = [];

      switch (rubricName) {
        case 'latest':
          rawPodcastsData = await Api.getLatestPodcasts();
          break;
        case 'top8':
          rawPodcastsData = await Api.getTop8Podcasts();
          break;
        default:
          rawPodcastsData = await Api.getPodcastsByRubricId(get().rubrics[rubricName].id);
      }

      const slicedPodcastsData = rawPodcastsData.slice(0, 100);
      const podcasts = slicedPodcastsData.map((podcast, index) => ({ ...podcast, index }));

      set(state => {
        if (rubricName === 'latest' || rubricName === 'top8') {
          const rubricStatusName = toUpperCaseFirst(rubricName);
          state[rubricName] = podcasts;
          state[`is${rubricStatusName}Loaded`] = true;
        } else {
          state.rubrics[rubricName].episodes = podcasts;
          state.rubrics[rubricName].isLoaded = true;
          if (rubricName === 'ASTON Радио шоу') state.isRadioRubricLoaded = true;
        }
      });
    } catch (e) {
      return e;
    }
  },

  // activeTrackSelector
  activeTrackId: null,
  activeTrackIndex: null,
  activeRubricName: null,
  activeTrack: null,
  prevTrack: null,
  nextTrack: null,

  setActiveRubricName(rubricName) {
    set({ activeRubricName: rubricName });
  },
  setActiveTrack(index, rubricName) {
    const { activeTrackIndex, activeRubricName, getRubricTracks } = get();

    if (index === activeTrackIndex && rubricName === activeRubricName) return;

    const rubricTracks = getRubricTracks(rubricName);

    set({
      activeTrackId: rubricTracks?.[index].id,
      activeTrackIndex: index,
      activeRubricName: rubricName,
      activeTrack: rubricTracks?.[index],
      prevTrack: rubricTracks?.[index - 1],
      nextTrack: rubricTracks?.[index + 1],
    });
  },
  setNextTrack() {
    const { nextTrack, getRubricTracks, activeTrackIndex } = get();
    const rubricTracks = getRubricTracks();

    set({
      activeTrackId: nextTrack.id,
      activeTrackIndex: nextTrack.index,
      activeTrack: rubricTracks?.[nextTrack.index],
      prevTrack: rubricTracks?.[activeTrackIndex],
      nextTrack: rubricTracks?.[nextTrack.index + 1],
    });
  },
  setPrevTrack() {
    const { prevTrack, getRubricTracks, activeTrackIndex } = get();
    const rubricTracks = getRubricTracks();

    set({
      activeTrackId: prevTrack.id,
      activeTrackIndex: prevTrack.index,
      activeTrack: rubricTracks?.[prevTrack.index],
      prevTrack: rubricTracks?.[prevTrack.index - 1],
      nextTrack: rubricTracks?.[activeTrackIndex],
    });
  },
  getRubricTracks(rubricName = get().activeRubricName) {
    const { latest, top8, rubrics } = get();

    switch (rubricName) {
      case 'latest':
        return latest;
      case 'top8':
        return top8;
      default:
        return rubrics[rubricName]?.episodes;
    }
  },

  // radioStatusesSelector
  isLatestLoaded: false,
  isTop8Loaded: false,
  isRubricsLoaded: false,
  isRadioRubricLoaded: false,
});
