import {
  VStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { transformDate } from 'shared/lib/transformDate';
import { msToTime } from 'shared/lib/msToTime';
import RadioLogo from 'shared/RadioLogo';

export default function AudioProgress({
  activeTrack = { publication_date: '0.0', duration: 0, id: '' },
  last5RadioTracks,
}) {
  const [progress, setProgress] = useState(0);
  const [isCaptured, setIsCapture] = useState(false);
  const { getPosition, seek, playing } = useGlobalAudioPlayer();
  const { isLight } = useBoundStore(headerSelector);

  const cachedDur = useMemo(() => msToTime(activeTrack?.duration ?? 0), [activeTrack?.duration]);
  const isTitleVisible = useMemo(
    () =>
      last5RadioTracks?.length &&
      activeTrack?.id &&
      last5RadioTracks?.some(track => track.id === activeTrack?.id),
    [activeTrack?.id],
  );
  const title = useMemo(
    () => `- Выпуск ${transformDate(activeTrack?.publication_date)}`,
    [activeTrack?.publication_date],
  );

  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        isCaptured || setProgress(getPosition());
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isCaptured, playing]);

  return (
    <VStack w="full" minW="160px" align="start" spacing="10px" color={isLight && 'text.white'}>
      <HStack w="full" align="center" justify={isTitleVisible ? 'center' : 'start'}>
        <RadioLogo />
        {isTitleVisible && (
          <Text textStyle="radio" fontWeight={600}>
            {title}
          </Text>
        )}
      </HStack>

      <VStack w="full" spacing={0}>
        <Slider
          w="full"
          step={1}
          alignItems="center"
          focusThumbOnChange={false}
          value={progress}
          max={activeTrack?.duration}
          onChangeStart={() => setIsCapture(true)}
          onChange={setProgress}
          onChangeEnd={val => {
            setIsCapture(false);
            seek(val);
          }}
        >
          <SliderTrack bgColor="#E3F9A4" borderRadius="full" h="1.6px" cursor="pointer">
            <SliderFilledTrack
              bgColor={`bg.${isLight ? 'white' : 'black'}`}
              h="1.6px"
              borderRadius="full"
            />
          </SliderTrack>
          <SliderThumb
            boxSize="6px"
            bgColor="bg.accent"
            borderRadius="full"
            cursor="pointer"
            top="50%"
          />
        </Slider>
        <HStack w="full" fontSize="12px" fontWeight={400} justify="space-between">
          <Text>{msToTime(progress)}</Text>
          <Text>{cachedDur}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
}
