import { Box } from '@chakra-ui/react';
import React, { memo } from 'react';
import { spinAnimation } from '../model/constants';

const AnimatedCircles = ({ circle1 = undefined, circle2 = undefined, duration = 5, ...props }) => {
  return (
    <Box
      className="circles"
      id="circles"
      position="absolute"
      zIndex={-1}
      transform="translateZ(0) translate3d(0, 0, 0)"
      willChange="transform"
      borderRadius="full"
      animation={`${spinAnimation} 5s linear infinite`}
      {...props}
    >
      <Box
        className="circle-1"
        pos="absolute"
        boxSize={{ tablet: '350px', mobile: '200px' }}
        zIndex={-1}
        flexShrink={0}
        borderRadius="50%"
        opacity="0.6"
        top={{ tablet: '200px', mobile: '100px' }}
        left={{ laptop: '-300px', tablet: '-300px', mobile: '-350px' }}
        bgColor="bg.accent"
        boxShadow={{ mobile: '0 0 200px 200px #93f224', tablet: '0 0 200px 350px #93f224' }}
        {...circle1}
      />
      <Box
        className="circle-2"
        pos="absolute"
        boxSize={{ mobile: '150px', tablet: '200px' }}
        zIndex={-1}
        flexShrink={0}
        borderRadius="full"
        opacity="0.7"
        bgColor="bg.neonLight"
        left={{ mobile: '-100px', tablet: '150px' }}
        top="-150px"
        boxShadow={{ tablet: '0 0 150px 200px #e3f9a4', mobile: '0 0 150px 150px #e3f9a4' }}
        {...circle2}
      />
    </Box>
  );
};
export default memo(AnimatedCircles);
