export const activeTrackSelector = ({
  activeTrackId,
  activeTrackIndex,
  activeRubricName,
  activeTrack,
  prevTrack,
  nextTrack,

  setActiveTrack,
  setNextTrack,
  setPrevTrack,

  getRubricTracks,
}) => ({
  activeTrackId,
  activeTrackIndex,
  activeRubricName,
  activeTrack,
  prevTrack,
  nextTrack,

  setActiveTrack,
  setNextTrack,
  setPrevTrack,

  getRubricTracks,
});
