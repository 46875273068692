import { setCookie } from 'utils/cookie';

const params = new URLSearchParams(window.location.search);
const gclid = params.get('gclid');
if (gclid) {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 3);

  setCookie('gclid', gclid, { expires });
}
