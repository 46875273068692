/**
 * Преобразует дату формата 'ГГГГ-ММ-ДД' в формат 'ДД.ММ'.
 *
 * @param {string} date - Строка с датой в формате 'ГГГГ-ММ-ДД'.
 * @returns {string} Дата в формате 'ДД.ММ'.
 *
 * @example
 * transformDate('2024-09-10');
 * // Вернёт '10.09'
 */
export const transformDate = date => date?.split('-').slice(1).reverse().join('.');
