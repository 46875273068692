import React, { memo, useMemo } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import { useBoundStore } from 'app/store';
import { activeTrackSelector, radioSelector } from 'app/selectors';
import PlayPauseButton from 'shared/PlayPauseButton';
import PlaylistControl from 'shared/PlaylistControl';
import AudioProgress from 'shared/AudioProgress';
import VolumeControl from 'shared/VolumeControl';
import { ym } from 'shared/lib/ym';

const AstonRadio = ({ ...props }) => {
  const { rubrics } = useBoundStore(radioSelector);
  const { activeTrack } = useBoundStore(activeTrackSelector);
  const { togglePlayPause, setVolume, playing, volume } = useGlobalAudioPlayer();

  const handlePlayPause = () => {
    ym('radio_play_button_click');
    togglePlayPause();
  };

  const last5RadioTracks = useMemo(
    () => rubrics?.['ASTON Радио шоу']?.episodes?.slice(0, 5),
    [rubrics?.['ASTON Радио шоу']?.episodes?.length],
  );

  return (
    <Stack direction="row" spacing="26px" align="center" whiteSpace="nowrap" {...props}>
      <PlayPauseButton isPlaying={playing} onPlayPauseClick={handlePlayPause} />
      <AudioProgress activeTrack={activeTrack} last5RadioTracks={last5RadioTracks} />
      <HStack spacing="16px" align="center">
        <VolumeControl onVolumeChange={setVolume} volume={volume} />
        <PlaylistControl last5RadioTracks={last5RadioTracks} />
      </HStack>
    </Stack>
  );
};

export default memo(AstonRadio);
