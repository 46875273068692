const Request = require('./request');
const { getApiUrl } = require('../../utils/environment');
const API_LINK = `${getApiUrl()}api/v14/`;

class Api extends Request {
  constructor() {
    super(API_LINK);
  }

  getAllCvs(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('cvs', headers);
  }

  getCaptchaToken() {
    return window.grecaptcha
      ?.execute('6Lc3x7UpAAAAAN7y4W3PKG-DE_2pxPSme5C0E_e2', {
        action: 'submit',
      })
      .catch(() => 'no-token');
  }

  getLatestArticles(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`articles/latest`, headers);
  }

  getMapInfo(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('projects', headers);
  }

  getOffices(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('offices', headers);
  }

  getProjectPages(lang = 'ru') {
    const headers = { 'Accept-Language': lang };

    return this.get('projects/pages', headers);
  }

  getProjects(page, lang = 'ru') {
    const headers = {
      'Accept-Language': lang,
    };

    return this.get(`projects/cards/${page}`, headers);
  }

  getRelatedCases(key, id, lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`projects/related/${key}/${id}`, headers);
  }

  getReviews(page, lang, opts) {
    const headers = { ...opts, 'Accept-Language': lang };

    return this.get(`reviews/${page}`, headers);
  }

  getTestimonials(country, lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`testimonials/${country}`, headers);
  }

  getTopCompaniesInfo(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('projects/top', headers);
  }

  getTrips(count) {
    return this.get(`trips/${count}`);
  }

  getTripsData(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('lets-meet-tomorrow', headers);
  }

  sendFormData(data) {
    return this.postFormData('mail/send', data);
  }

  sendFormDataCV(data) {
    return this.postFormData('cv', data);
  }

  setViewCount(id) {
    return this.put(`articles/views/${id}`);
  }

  getYoutubeData(url) {
    return this.get(url);
  }

  getRadioData() {
    return this.get('radio');
  }

  getPodcastsRubrics() {
    return this.get('radio/rubrics');
  }

  getPodcastsByRubricId(id) {
    return this.get(`radio/${id}`);
  }

  getLatestPodcasts() {
    return this.get('radio/latest');
  }

  getTop8Podcasts() {
    return this.get('radio/top');
  }
}

module.exports = new Api();
