/**
 * Преобразует первый символ строки в заглавную букву и возвращает изменённую строку.
 *
 * @param {string} str - Входная строка для преобразования.
 * @returns {string} Изменённая строка с первой заглавной буквой.
 *
 * @example
 * toUpperCaseFirst('привет');
 * // 'Привет'
 */
export const toUpperCaseFirst = str => str[0].toUpperCase() + str.slice(1);
