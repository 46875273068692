import {
  AccordionButton,
  Text,
  AccordionItem,
  AccordionPanel,
  HStack,
  Image,
  Link,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';

const AccordionInfo = (
  {
    title = null,
    titleSx = null,
    RenderTitle = null,
    content,
    contentSx = null,
    panelStyle = null,
    containerStyle = null,
    leftIcon = null,
    rightIcon = null,
    link = null,
  },
  _,
) => (
  <AccordionItem w="full" border="none" {...containerStyle}>
    {({ isExpanded }) => (
      <>
        <AccordionButton
          w="full"
          flex={1}
          justifyContent="space-between"
          gap="12px"
          p="0"
          _hover={{}}
        >
          <HStack w="full" spacing="12px">
            {leftIcon}
            {title && (
              <Text align="start" textStyle="h1" {...titleSx}>
                {title}
              </Text>
            )}
            {RenderTitle}
          </HStack>
          {rightIcon && (
            <Image
              src={rightIcon}
              transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
              transition="transform .2s"
            />
          )}
        </AccordionButton>
        <AccordionPanel motionProps={{ unmountOnExit: true }} {...panelStyle}>
          <Text dangerouslySetInnerHTML={{ __html: content }} mb={link && '20px'} {...contentSx} />
          {link && (
            <Link
              as={GatsbyLink}
              to={link}
              display="block"
              mt="20px"
              textStyle="button.secondary"
              textDecoration="underline"
              textUnderlineOffset="3px"
              mb="1px"
              textAlign="end"
            >
              Подробнее
            </Link>
          )}
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);

export default forwardRef(AccordionInfo);
