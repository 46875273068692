import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
  addon: {},
  field: {
    fontSize: { laptop: '18px', mobile: '16px' },
    pr: '0',
    textStyle: 'h2',
    focusBorderColor: 'white !important',
    _focus: {
      caretColor: '#93F224',
      border: 'none',
    },
    borderColor: 'white',
    w: 'full',
    _placeholder: { textStyle: 'h2', color: '#575C66', fontWeight: '400' },
    color: 'text.black',
    paddingX: '0',
  },
  element: {
    pos: 'relative',
    display: 'flex',
    transition: 'opacity .1s',
    opacity: '0',
  },
});

const md = defineStyle({ px: '0', h: '32px' });
const sm = defineStyle({ px: '0', h: '17px' });
const xs = defineStyle({ px: '0', h: '17px' });

const sizes = {
  md: definePartsStyle({ field: md, addon: md, element: md }),
  sm: definePartsStyle({ field: sm, addon: sm, element: sm }),
  xs: definePartsStyle({ field: xs, addon: xs, element: xs }),
};

const Input = defineMultiStyleConfig({ baseStyle, sizes, defaultProps: { size: 'md' } });

export default Input;
