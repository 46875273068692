const styles = {
  global: {
    'h1,h2,h3,h4': {
      m: '0px',
      p: '0px',
      fontWeight: 500,
    },
    '*': {
      WebkitTapHighlightColor: 'transparent',
    },
    'img,svg': {
      userSelect: 'none',
    },
    '.grecaptcha-badge': {
      display: 'none !important',
    },
    'input,textarea': {
      outline: 'none',
    },
    h1: {
      textStyle: 'header.h1',
    },
    h2: {
      textStyle: 'header.h2',
    },
    h3: {
      textStyle: 'header.h3',
    },
    h4: {
      textStyle: 'header.h4',
    },
    html: {
      minH: '100vh',
    },
    body: {
      minW: '100vw',
      position: 'relative',
      overflowX: 'hidden',
      zIndex: 1,
      bgColor: 'bg.white',
      textTransform: 'none',
      textStyle: 'h1',
      color: 'text.black',
      flexDirection: 'column',
      alignItems: 'center',
      boxSizing: 'border-box',
      '&::-webkit-scrollbar': {
        zIndex: 1,
        width: '10px',
        bgColor: 'transparent',
      },
      '&::-webkit-scrollbar-track': {
        zIndex: 1,
        width: '10px',
        bgColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        zIndex: 1,
        bgColor: 'bg.accent',
        borderRadius: '24px',
      },
    },
  },
};

export default styles;
