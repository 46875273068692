export const CONSENT_COOKIE_NAME = 'consent_status';
export const CONSENT_MODAL_ID = 'consentModal';

export const getCookieOptions = () => {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 3);

  const domain = window.location.hostname === 'localhost' ? 'localhost' : `astondevs.ru`;

  return { expires, domain };
};
