import { motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import { Flex, Link as ChakraLink, Show } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useRef, memo } from 'react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import AstonRadio from 'features/AstonRadio';
import MobileNavigation from '~entities/MobileNavigation';
import Navigation from '~entities/Navigation';
import MenuToggle from 'shared/MenuToggle';
import Logo from 'shared/Logo';

const Header = (_, ref) => {
  const { isMenuOpen, isAnimated, isLight, setIsAnimated } = useBoundStore(headerSelector);
  const raf = useRef(null);

  useEffect(() => {
    function spyHeader() {
      if (window.scrollY >= 20) {
        setIsAnimated(true);
      } else {
        setIsAnimated(false);
      }
      raf.current = requestAnimationFrame(spyHeader);
    }
    raf.current = requestAnimationFrame(spyHeader);
    return () => cancelAnimationFrame(raf.current);
  }, []);

  return (
    <Flex
      as={motion.header}
      initial={false}
      animate={isMenuOpen ? 'open' : 'closed'}
      ref={ref}
      id="header"
      pos="fixed"
      top={0}
      left={0}
      bgColor={isAnimated ? 'rgba(255, 255, 255, 0.40)' : 'transparent'}
      backdropFilter="blur(20px)"
      zIndex={1200}
      justifyContent="space-between"
      gap="20px"
      alignItems="center"
      boxSizing="border-box"
      borderStyle="solid"
      transition="border-width .1s linear, background-color .2s linear"
      borderBottomWidth={isAnimated ? '1.6px' : '0px'}
      borderColor={`text.${isLight ? 'white' : 'black'}`}
      p={{ laptop: '20px 40px', tablet: '20px 24px', mobile: '0px 16px' }}
      w="100vw"
    >
      <ChakraLink as={GatsbyLink} to="/">
        <Logo w={{ mg: '164px', mobile: '123px' }} />
      </ChakraLink>

      <Show breakpoint="(min-width: 1600px)">
        <AstonRadio />
      </Show>

      <Navigation hideBelow="mg" />
      <MenuToggle hideFrom="mg" />
      <MobileNavigation hideFrom="mg" />
    </Flex>
  );
};

export default memo(forwardRef(Header));
