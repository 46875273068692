const Checkbox = {
  baseStyle: {
    border: '1.6px solid',
    borderColor: 'text.black',
    pos: 'relative',
    zIndex: 10,
    control: {
      boxSize: '24px',
      pos: 'relative',
      zIndex: 15,
      border: '1.6px solid',
      borderColor: 'text.black',
      borderRadius: '7px',
      boxSizing: 'border-box',
      _checked: {
        bgColor: 'bg.accent',
        border: 'none',
      },
    },
    icon: {
      boxSize: '13px',
      color: 'text.white',
    },
    label: {
      textStyle: 'h3',
      m: '0px',
    },
    container: {
      display: 'flex',
      gap: '12px',
    },
  },
  variants: {
    error: {
      label: {
        color: 'alert.active',
      },
      control: {
        borderColor: 'alert.active',
        _checked: {
          bgColor: 'alert.active',
        },
      },
    },
  },
};

export default Checkbox;
