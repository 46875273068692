import { Tooltip } from '@chakra-ui/react';
import React, { memo } from 'react';

const SuccessPopup = ({ children }) => {
  return (
    <Tooltip
      openDelay={2}
      closeDelay={2}
      color="text.white"
      w="292px"
      p="8px 24px"
      bgColor="#3D3E3D"
      borderRadius="40px"
      fontSize="14px"
      textAlign="center"
      lineHeight="19.6px"
      label="Эта организация признана экстремистской и запрещена на территории России"
    >
      {children}
    </Tooltip>
  );
};
export default memo(SuccessPopup);
