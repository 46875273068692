import radio_big from 'assets/Radio/rubrics/radio/radio_big.png';
import radio_medium from 'assets/Radio/rubrics/radio/radio_medium.png';
import radio_small from 'assets/Radio/rubrics/radio/radio_small.png';

import rasti_v_it_big from 'assets/Radio/rubrics/rasti-v-it/rasti-v-it_big.png';
import rasti_v_it_medium from 'assets/Radio/rubrics/rasti-v-it/rasti-v-it_medium.png';
import rasti_v_it_small from 'assets/Radio/rubrics/rasti-v-it/rasti-v-it_small.png';

import kniga_zhalob_big from 'assets/Radio/rubrics/kniga-zhalob/kniga-zhalob_big.png';
import kniga_zhalob_medium from 'assets/Radio/rubrics/kniga-zhalob/kniga-zhalob_medium.png';
import kniga_zhalob_small from 'assets/Radio/rubrics/kniga-zhalob/kniga-zhalob_small.png';

import do_it_big from 'assets/Radio/rubrics/do-it/do-it_big.png';
import do_it_medium from 'assets/Radio/rubrics/do-it/do-it_medium.png';
import do_it_small from 'assets/Radio/rubrics/do-it/do-it_small.png';

import audioreportazh_big from 'assets/Radio/rubrics/audioreportazh/audioreportazh_big.png';
import audioreportazh_medium from 'assets/Radio/rubrics/audioreportazh/audioreportazh_medium.png';
import audioreportazh_small from 'assets/Radio/rubrics/audioreportazh/audioreportazh_small.png';

export const podcastsDataByRubrics = {
  'ASTON Радио шоу': {
    description: 'Рубрика для сотрудников компании и тех, кто интересуется жизнью внутри ASTON',
    img_big: radio_big,
    img_medium: radio_medium,
    img_small: radio_small,
    img_medium_styles: {
      w: { mobile: '209px', laptop: '236px' },
      h: { mobile: '236px', laptop: '266px' },
      top: { mobile: '44px', laptop: '29px' },
      right: { mobile: '-55px', laptop: '-39px' },
    },
  },

  'Расти в ИТ': {
    description: 'Короткие, но содержательные интервью с профессиональными спикерами из мира ИТ',
    img_big: rasti_v_it_big,
    img_medium: rasti_v_it_medium,
    img_small: rasti_v_it_small,
    img_big_styles: {
      w: { mobile: '207px', tablet: '226px', laptop: '592px' },
      h: { mobile: '182px', tablet: '199px', laptop: '521px' },
      top: { tablet: '0', laptop: '150px' },
      right: { tablet: '-55px', laptop: 'unset' },
    },
    img_medium_styles: {
      w: { mobile: '205px', laptop: '238px' },
      h: { mobile: '178px', laptop: '206px' },
      top: { mobile: '41px', laptop: '22px' },
      right: { mobile: '-21px', laptop: '0' },
    },
  },

  'Книга жалоб': {
    description: 'Разбираем реальные проблемы, с которыми сталкиваются айтишники',
    img_big: kniga_zhalob_big,
    img_medium: kniga_zhalob_medium,
    img_small: kniga_zhalob_small,
    img_big_styles: {
      w: { mobile: '207px', tablet: '224px', laptop: '592px' },
      h: { mobile: '182px', tablet: '197px', laptop: '521px' },
      top: { tablet: '9px', laptop: '192px' },
      right: { tablet: '-37px', laptop: 'unset' },
    },
    img_medium_styles: {
      top: { mobile: '20px', laptop: '3px' },
      right: '-67px',
    },
  },

  'До ИТ': {
    description: 'Рубрика про сотрудников ASTON, кто до этого работал в самых необычных профессиях',
    img_big: do_it_big,
    img_medium: do_it_medium,
    img_small: do_it_small,
  },

  Аудиорепортажи: {
    description: 'Невероятные истории, которые происходят в мире',
    img_big: audioreportazh_big,
    img_medium: audioreportazh_medium,
    img_small: audioreportazh_small,
  },

  default: {
    img_big: radio_big,
    img_medium: radio_medium,
    img_small: radio_small,
    img_medium_styles: {
      w: { mobile: '209px', laptop: '236px' },
      h: { mobile: '236px', laptop: '266px' },
      top: { mobile: '44px', laptop: '29px' },
      right: { mobile: '-55px', laptop: '-39px' },
    },
  },
};
