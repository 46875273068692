import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const variant = definePartsStyle(() => ({
  root: { display: 'flex', w: 'full', gap: '20px' },
  tab: {
    w: 'full',
    boxSize: 'border-box',
    ps: { laptop: '4px', mobile: '2px' },
    py: { laptop: '4px', mobile: '2px' },
    pe: { laptop: '32px', mobile: '16px' },
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: { laptop: '48px', mobile: '24px' },
    justifyContent: 'start',
    transition: 'all .2s',
    '.leftIcon': { boxSize: { laptop: '32px', mobile: '16px' } },
    '.rightIcon': { opacity: '0', boxSize: { laptop: '24px', mobile: '16px' } },
    _hover: {
      border: '1px solid',
      borderColor: 'line.gray !important',
      '.rightIcon': {
        transition: 'all .2s',
        opacity: '1',
      },
    },
    _selected: {
      bgColor: 'bg.grayLight',
      '.rightIcon': {
        opacity: '1',
      },
    },
  },
  tablist: { w: 'full', flexDirection: 'column', gap: { laptop: '24px', mobile: '16px' } },
  tabpanels: {
    w: 'full',
    overflow: 'hidden',
    p: { laptop: '48px', mobile: '24px' },
    borderRadius: { laptop: '48px', mobile: '24px' },
    bgColor: 'bg.gray',
  },
  tabpanel: { w: 'full', h: 'full' },
}));

const variants = {
  tabs: variant,
};

export default defineMultiStyleConfig({ variants });
