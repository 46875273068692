import { getBuildEnvironment } from 'utils/environment';

export const LOCAL_STORAGE_KEY = 'first-point';
export const SESSION_STORAGE_KEY = 'session-point';
export const DOMAIN_URL = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.astondevs.ru';
    case 'development':
      return 'http://dev.astondevs.ru';
    case 'production':
    default:
      return 'https://prod.astondevs.ru';
  }
})();

export const DOMAIN_URL_DE = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.astondevs.ru';
    case 'development':
      return 'http://dev.astondevs.ru';
    case 'production':
    default:
      return 'https://prod.astondevs.ru';
  }
})();

export const pageKeys = {
  total: 'total',
  webDevelopment: 'webDevelopment',
};
