const { getCookie } = require('../../utils/cookie');

const _fetch = typeof window === 'undefined' ? require('node-fetch') : window.fetch;
const { serialize } = require('object-to-formdata');

class Request {
  constructor(apiLink) {
    this.apiLink = apiLink;
  }

  request(url, opts, body) {
    const req = _fetch(`${this.apiLink}${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Cross-Origin-Resource-Policy': 'cross-origin',
        ...opts.headers,
      },
      ...opts,
      body,
    });

    return req.then(e => e.json());
  }

  get(url, headers) {
    return this.request(url, { method: 'GET', headers });
  }

  post(url, data, headers) {
    return this.request(url, { method: 'POST', headers }, data);
  }

  put(url, headers) {
    return this.request(url, { method: 'PUT', headers });
  }

  postFormData(url, data, opts) {
    const withUtm = {
      ...data,
      gclid: getCookie('gclid'),
    };
    const body = serialize(withUtm, {
      indices: true,
      nullsAsUndefineds: true,
    });

    const req = _fetch(`${this.apiLink}${url}`, {
      method: 'POST',
      headers: {
        'Cross-Origin-Resource-Policy': 'cross-origin',
      },
      ...opts,
      body,
    });

    return req.then(res => {
      if (false === res.ok) {
        throw new Error('Error has occurred while sending form');
      }

      return res.json();
    });
  }
}

module.exports = Request;
