
export const createHeaderSlice = set => ({
  isAnimated: false,
  isMenuOpen: false,
  isLight: false,
  toggleMenuOpen() {
    set(state => {
      state.isMenuOpen = !state.isMenuOpen;
    });
  },
  setIsAnimated(bool) {
    set(state => {
      state.isAnimated = bool;
    });
  },
  setIsLight(bool) {
    set(state => {
      state.isLight = bool;
    });
  },
});
