import { VStack } from '@chakra-ui/react';
import React, { memo } from 'react';

const Wrapper = ({ children, ...props }) => (
  <VStack
    pos="relative"
    zIndex={10}
    w="100%"
    maxW={{ desktop: '1440px', mobile: 'full' }}
    boxSizing="border-box"
    overflowX="hidden"
    px={{ laptop: '40px', tablet: '20px', mobile: '16px' }}
    gap={{ laptop: '180px', tablet: '120px', mobile: '80px' }}
    mt={{ mobile: '42px', tablet: '72px', laptop: '90px', xxl: '106px' }}
    pt={{ laptop: '147px', tablet: '160px', mobile: '148px' }}
    pb={{ laptop: '180px', tablet: '120px', mobile: '80px' }}
    {...props}
  >
    {children}
  </VStack>
);

export default memo(Wrapper);
