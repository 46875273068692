import { vacancyCardsData } from 'constants/vacancies/cards';

export const createVacanciesSlice = set => ({
  vacancies: vacancyCardsData,
  searchQuery: '',
  setSearchQuery(query) {
    set(state => {
      state.searchQuery = query.toLowerCase();
    });
  },
});
