export const appendScriptSrc = (src, location = 'head') => {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;
  scriptElement.async = true;
  document[location].appendChild(scriptElement);
};

export const appendScriptInnerHtml = (scriptInnerHTML, location = 'head') => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptInnerHTML;
  document.head.appendChild(scriptElement);
};

export const appendNoscriptInnerHtml = (noscript, location = 'head') => {
  const noscriptElement = document.createElement('noscript');
  const img = document.createElement('img');
  img.alt = noscript.alt;
  img.src = noscript.src;
  Object.keys(noscript.styles).forEach(key => {
    img.style[key] = noscript.styles[key];
  });
  document.head.appendChild(noscriptElement);
};
