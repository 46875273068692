export const headerSelector = ({
  isAnimated,
  isMenuOpen,
  isLight,
  toggleMenuOpen,
  setIsAnimated,
  setIsLight
}) => ({
  isAnimated,
  isMenuOpen,
  isLight,
  toggleMenuOpen,
  setIsAnimated,
  setIsLight,
});
