import { defineStyleConfig } from '@chakra-ui/react';

const baseStyle = {
  fontSize: { laptop: '18px', mobile: '16px' },
  textStyle: 'h2',
  w: 'full',
  h: 'full',
  resize: 'none',
  p: '0',
  focusBorderColor: 'white',
  _focus: {
    caretColor: '#93f224',
  },
  _placeholder: {
    textStyle: 'h2',
    fontWeight: '400',
    color: '#605E5E',
  },
};

const Textarea = defineStyleConfig({ baseStyle });

export default Textarea;
