export const throttle = (func, ms) => {
  // объявляем функцию throttle

  let locked = false; // переменная которая отвечает за блокировку вызова функции

  return function () {
    // эта функция запускается при каждом событии движения курсора

    if (locked) return; // если заблокировано, то прекращаем выполнение этой функции

    const context = this; // запоминаем передаваемую функцию func
    const args = arguments; // запоминаем параметры передаваемой функции func

    locked = true; // блокируем вызов функции

    setTimeout(() => {
      // устанавливаем время ожидания

      func.apply(context, args); // выполняем переданную функцию func
      locked = false; // снимаем блокировку
    }, ms); // подставляем значение параметра ms
  };
};
