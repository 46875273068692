const colors = {
  bg: {
    black: '#0D0E0C',
    white: '#FFFFFF',
    neonLight: '#E3F9A4',
    gray: '#EAEFE8',
    grayLight: '#F8F8F8',
    accent: '#93F224',
  },
  text: {
    black: '#0D0E0C',
    blackHalf: 'rgba(13, 14, 12,.5)',
    white: '#FFFFFF',
    gray: '#A7A9A5',
    darkGray: '#333531',
  },
  buttons: { black: '#0D0E0C', disabled: '#E6E9EE', gray: '#EAEFE8' },
  line: { neon: '#93F224', gray: '#EAEFE8', green: '#93F224' },
  icon: {
    black: '#0D0E0C',
    gray: '#EAEFE8',
  },
  alert: {
    active: '#D74242',
    default: '#FF3A40',
    hover: '#D50B11',
    secondary: '#FFE4D7',
  },
};

export default colors;
